<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Enter appropriate identifying information for each chemical used during Fundamental Skills.
      </p>

      <table>
        <thead>
          <tr>
            <th v-for="head in headings" :key="head.text">
              {{ head.text }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Crystal Violet Solution</td>
            <td style="width: 150px">
              <chemical-notation-input
                v-model="inputs.chemFormulaCV"
                class="mb-5"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.mmCV"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <v-select v-model="inputs.physStateCV" :items="stateOptions" outlined hide-details>
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
              </v-select>
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.vCV"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.cCV"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td>N/A</td>
          </tr>

          <tr>
            <td>Sodium Hydroxide Stock Solution</td>
            <td style="width: 150px">
              <chemical-notation-input
                v-model="inputs.chemFormulaStock"
                class="mb-5"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.mmStock"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <v-select v-model="inputs.physStateStock" :items="stateOptions" outlined hide-details>
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
              </v-select>
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.vStock"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.cStock"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td>N/A</td>
          </tr>

          <tr>
            <td>Sodium Hydroxide Solution #1 (most concentrated)</td>
            <td style="width: 150px">
              <chemical-notation-input
                v-model="inputs.chemFormulaSol1"
                class="mb-5"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.mmSol1"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <v-select v-model="inputs.physStateSol1" :items="stateOptions" outlined hide-details>
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
              </v-select>
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.vSol1"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.cSol1"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td style="width: 250px">
              <v-select v-model="inputs.sourceSol1" :items="sourceOptions" outlined hide-details>
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
              </v-select>
            </td>
          </tr>

          <tr>
            <td>Sodium Hydroxide Solution #2</td>
            <td style="width: 150px">
              <chemical-notation-input
                v-model="inputs.chemFormulaSol2"
                class="mb-5"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.mmSol2"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <v-select v-model="inputs.physStateSol2" :items="stateOptions" outlined hide-details>
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
              </v-select>
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.vSol2"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.cSol2"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <v-select v-model="inputs.sourceSol2" :items="sourceOptions" outlined hide-details>
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
              </v-select>
            </td>
          </tr>

          <tr>
            <td>Sodium Hydroxide Solution #3 (most dilute)</td>
            <td style="width: 150px">
              <chemical-notation-input
                v-model="inputs.chemFormulaSol3"
                class="mb-5"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.mmSol3"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <v-select v-model="inputs.physStateSol3" :items="stateOptions" outlined hide-details>
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
              </v-select>
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.vSol3"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.cSol3"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <v-select v-model="inputs.sourceSol3" :items="sourceOptions" outlined hide-details>
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
              </v-select>
            </td>
          </tr>

          <tr>
            <td>Deionized Water</td>
            <td style="width: 150px">
              <chemical-notation-input
                v-model="inputs.chemFormulaH2O"
                class="mb-5"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.mmH2O"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <v-select v-model="inputs.physStateH2O" :items="stateOptions" outlined hide-details>
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
              </v-select>
            </td>
            <td>N/A</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
        </tbody>
      </table>
      <p class="mt-5">Select all the glassware/equipment/instrumentation used in this lab.</p>
      <p v-for="option in equipmentOptions" :key="option.value" class="mb-0 mt-0">
        <v-checkbox v-model="inputs.equipment" :value="option.value" class="ml-6 mb-n4 mt-0">
          <template v-slot:label>
            <stemble-latex :content="option.value" />
          </template>
        </v-checkbox>
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';
import ChemicalNotationInput from '@/chemical-equations/components/ChemicalNotationInput.vue';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';

export default {
  name: 'ChemUCI1LCFS3IL2',
  components: {
    CalculationInput,
    ChemicalNotationInput,
    ChemicalLatex,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        chemFormulaCV: null,
        mmCV: null,
        physStateCV: null,
        vCV: null,
        cCV: null,

        chemFormulaStock: null,
        mmStock: null,
        physStateStock: null,
        vStock: null,
        cStock: null,

        chemFormulaSol1: null,
        mmSol1: null,
        physStateSol1: null,
        vSol1: null,
        cSol1: null,
        sourceSol1: null,

        chemFormulaSol2: null,
        mmSol2: null,
        physStateSol2: null,
        vSol2: null,
        cSol2: null,
        sourceSol2: null,

        chemFormulaSol3: null,
        mmSol3: null,
        physStateSol3: null,
        vSol3: null,
        cSol3: null,
        sourceSol3: null,

        chemFormulaH2O: null,
        mmH2O: null,
        physStateH2O: null,

        equipment: [],
      },
      headings: [
        {text: 'Chemical Name'},
        {text: 'Formula'},
        {text: 'Molar Mass (g/mol)'},
        {text: 'Physical States'},
        {text: 'Total Volume Used (mL)'},
        {text: 'Concentration (M)'},
        {text: '1/10 Dilution: Source of Vconc'},
      ],
      stateOptions: ['l', 's', 'g', 'aq'],
      sourceOptions: [
        'Crystal Violet Solution',
        'Sodium Hydroxide Stock Solution',
        'Sodium Hydroxide Solution #1 (most concentrated)',
        'Sodium Hydroxide Solution #2',
        'Sodium Hydroxide Solution #3 (most dilute)',
        'Deionized Water',
      ],
      equipmentOptions: [
        {value: 'beaker(s)'},
        {value: 'Buchner funnel'},
        {value: 'buret'},
        {value: 'capillary/melting tube'},
        {value: 'clamp and stand'},
        {value: 'cuvette'},
        {value: 'Digi-Melt (melting point apparatus)'},
        {value: 'digital balance'},
        {value: 'disposable pipet'},
        {value: 'drying oven'},
        {value: 'Erlenmeyer flask'},
        {value: 'filter paper'},
        {value: 'funnel'},
        {value: 'graduated cylinder'},
        {value: 'ice'},
        {value: 'KimWipes (tissues)'},
        {value: 'LabQuest'},
        {value: 'pencil'},
        {value: 'pipet suction bulb'},
        {value: 'probe (pH)'},
        {value: 'probe (temperature)'},
        {value: 'scintillation vial'},
        {value: 'side arm flask'},
        {value: 'spatula or scoopula'},
        {value: 'spectrometer'},
        {value: 'spotter'},
        {value: 'stir bar'},
        {value: 'stirrer/hotplate'},
        {value: 'test tube'},
        {value: 'thermometer (alcohol)'},
        {value: 'TLC (developing) chamber'},
        {value: 'TLC plate'},
        {value: 'TLC spotter'},
        {value: 'UV lamp'},
        {value: 'volumetric flask'},
        {value: 'volumetric pipet'},
        {value: 'watch glass'},
        {value: 'weighing boat or paper'},
      ],
    };
  },
};
</script>
<style scoped>
table {
  border-collapse: collapse;
}

td,
th {
  border-style: solid;
  border-width: 1px;
  padding: 10px;
}
</style>
